import styled from '@emotion/styled'

export const HeaderContainer = styled.header`
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 5rem;
  margin: 0;
  padding: 2rem;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  
  > div {
    display: flex;
    align-items: center;

    img {
      filter: invert(1);
    }
  }
`

export const FooterMenuContainer = styled.header`
  display: block;
  width: 100%;
  max-width: 32rem;
  min-height: 5rem;
  margin: 0 auto;
  padding: 2rem;

  > div {
    &:first-of-type {
      margin-bottom: 2rem;
      a {
        display: block;
        > img {
          width: 6rem;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
`